import model from './model';
import {
  CollectionMSBWidgetComponentIds as ComponentIds,
  CollectionMSBWidgetStates as States,
} from './config/constants';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isEditor } = flowAPI.environment;
  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];
  let collectionProjects: Project[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiState),
    galleryWidget: $w(ComponentIds.GalleryWidget) as any,
    collectionWidget: $w(ComponentIds.CollectionWidget),
    emptyStateWidget: $w(ComponentIds.CollectionEmptyStateWidget),
  });

  const initMSB = async (numOfCollections: number) => {
    switch (numOfCollections) {
      case 0:
        await components.multiStateBox.changeState(States.EmptyState);
        break;
      case 1:
        await components.multiStateBox.changeState(States.Collection);
        break;
      default:
        await components.multiStateBox.changeState(States.Gallery);
        break;
    }
  };

  const initCollectionMSBWidgetData = async (numOfCollections: number) => {
    switch (numOfCollections) {
      case 0:
        if (!isEditor) {
          components.emptyStateWidget.collapse();
        }
        break;
      case 1:
        components.collectionWidget.data = {
          collection: collections[0],
          projects: collectionProjects,
        };
        break;
      default:
        components.galleryWidget.data = { collections };
        break;
    }
  };

  const initCollectionMSB = async () => {
    collections = $widget.props?.data?.collections;
    collectionProjects = $widget.props?.data?.collectionProjects || [];

    if (!collections) {
      return;
    }

    await initMSB(collections.length);
    await initCollectionMSBWidgetData(collections.length);
  };
  $widget.onPropsChanged(async (_, nextProps) => {
    components = getComponents();
    await initCollectionMSB();
  });

  return {
    pageReady: async () => {
      components = getComponents();
      await initCollectionMSB();
    },
    exports: {},
  };
});
