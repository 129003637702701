import model from './model';
import { PortfolioPageWidgetComponentIds as ComponentIds } from './config/constants';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import {
  Collection,
  SortOrder,
} from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import { getPageViewMode } from '../../utils';
import { queryProjectsWithCollectionInfo } from '@wix/ambassador-portfolio-projects-v1-project/http';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  const { isViewer, isSSR } = flowAPI.environment;
  const shouldShowEmptyComponent = isSSR;

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];
  let collectionProjects: Project[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBoxWidget: $w(ComponentIds.MultiStateWidget),
  });
  const getCollectionProjects = async (collectionId) => {
    return flowAPI.httpClient
      .request(
        queryProjectsWithCollectionInfo({
          query: {
            filter: {
              collectionId: { $eq: collectionId },
              hidden: { $eq: false },
            },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { projects = [] },
        } = res;
        return projects;
      });
  };
  const getCollections = async () => {
    return flowAPI.httpClient
      .request(
        queryCollections({
          query: {
            filter: { hidden: false },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { collections = [] },
        } = res;
        return collections;
      });
  };

  const initPortfolioPageData = async () => {
    components.multiStateBoxWidget.data = { collections, collectionProjects };
  };
  const initEmptyCollection = async () => {
    components.multiStateBoxWidget.data = { collections: [] };
  };

  const initPortfolio = async () => {
    try {
      collections = await getCollections();
      if (collections.length === 1) {
        const projectsInCollection = await getCollectionProjects(
          collections[0].id,
        );
        collectionProjects = projectsInCollection.map(
          (p) => p.project,
        ) as Project[];
      }
      await initPortfolioPageData();
    } catch (e) {
      if (shouldShowEmptyComponent) {
        await initEmptyCollection();
      }
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      await initPortfolio();
      biLogger.report(
        portfolioPageViewed({
          pageName: 'portfolio',
          viewMode: getPageViewMode(flowAPI),
        }),
      );
    },
    exports: {},
  };
});
