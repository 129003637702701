import model from './model';
import { CollectionWidgetComponentIds as ComponentIds } from './config/constants';
import { CollectionWidgetProps as Props } from './config/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import { getProjectUrl } from '../../utils';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer, isSSR } = flowAPI.environment;
  const shouldShowEmptyComponent = isSSR;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    description: $w(ComponentIds.Description),
    gallery: $w(ComponentIds.Gallery),
  });

  const getWixCodePGItems = (projects: Project[], collectionSlug) => {
    const projectCoverImages = projects.map((project: Project) => {
      const item: Item & { projectSlug: string; collectionSlug: string } = {
        image: project.coverImage,
        title: project?.title,
        projectSlug: project.slug || '',
        collectionSlug,
      };
      return item;
    });
    return commonImagesToWixCode(projectCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;

    if (data?.collection?.id) {
      const { title, description } = data.collection;
      components.title.text = title;
      components.description.text = description;
      components.gallery.items = getWixCodePGItems(
        data.projects,
        data.collection.slug,
      );
      components.gallery.onItemClicked((event) => {
        const { collectionSlug, projectSlug } =
          components.gallery.items[event.itemIndex];
        flowAPI.controllerConfig.wixCodeApi.location.to &&
          flowAPI.controllerConfig.wixCodeApi.location.to(
            getProjectUrl(flowAPI, collectionSlug, projectSlug),
          );
      });
    } else if (shouldShowEmptyComponent) {
      components.title.text = '';
      components.description.text = '';
      components.gallery.items = [];
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    initByProps(nextProps);
  });

  return {
    pageReady: async () => {
      components = getComponents();
      if (components.topContainer.id) {
        initByProps($widget.props);
      }
    },
    exports: {},
  };
});
