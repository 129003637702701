import model from './model';
import { ProjectPageWidgetComponentIds as ComponentIds } from './config/constants';
import {
  queryProjects,
  getProjectPageData,
} from '@wix/ambassador-portfolio-projects-v1-project/build/es/http';
import { GetProjectPageDataResponse } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { listProjectItems } from '@wix/ambassador-portfolio-project-items-v1-project-item/http';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import {
  getCollectionSlugFromUrl,
  getProjectSlugFromUrl,
  getPageViewMode,
  isSeoTagsEnabled,
} from '../../utils';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';

export interface ProjectPageData extends GetProjectPageDataResponse {
  projectItems?: Item[];
}

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer } = flowAPI.environment;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  let components: ReturnType<typeof getComponents>;
  let projectPageData: ProjectPageData = {};

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectWidget1: $w(ComponentIds.ProjectWidget1),
  });

  const getFirstProject = async () => {
    return flowAPI.httpClient
      .request(queryProjects({ query: {} }))
      .then((res) => {
        const {
          data: { projects = [] },
        } = res;
        return { project: projects[0] };
      });
  };

  const getProjectBySlug = async () => {
    const projectSlug = getProjectSlugFromUrl(flowAPI);
    const collectionSlug = getCollectionSlugFromUrl(flowAPI);
    const res = await flowAPI.httpClient.request(
      getProjectPageData({ collectionSlug, projectSlug }),
    );

    return res.data;
  };

  const initProjectPageData = async () => {
    const routerData = flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData();

    // @ts-ignore
    projectPageData = routerData.projectPageData;

    if (!projectPageData.project) {
      projectPageData = isViewer
        ? await getProjectBySlug()
        : ((await getFirstProject()) as GetProjectPageDataResponse);
    }

    if (!projectPageData.projectItems && projectPageData.project?.id) {
      projectPageData.projectItems = await getProjectItems(
        projectPageData.project.id,
      );
    }

    setProjectWidgetInnerProps();
  };

  const getProjectItems = async (projectId) => {
    const res = await flowAPI.httpClient.request(
      listProjectItems({ projectId, paging: { limit: 200 } }),
    );
    return res.data.items || [];
  };

  const setProjectWidgetInnerProps = () => {
    const projectWidgetData = {
      project: projectPageData.project,
      items: projectPageData.projectItems,
      nextProjectSlug: projectPageData.nextProject?.slug || '',
      prevProjectSlug: projectPageData.previousProject?.slug || '',
    };
    components.projectWidget1.data = projectWidgetData;
  };

  const initProjectPage = async () => {
    await initProjectPageData();
  };

  const onPropsChanged = async () => {
    await initProjectPageData();
  };

  return {
    pageReady: async () => {
      components = getComponents();
      // check if we have components to init
      if (components.topContainer.id) {
        const isFirstTime =
          // @ts-expect-error
          !flowAPI.controllerConfig?.livePreviewOptions?.compsIdsToReset;
        isFirstTime ? await initProjectPage() : await onPropsChanged();
        biLogger.report(
          portfolioPageViewed({
            pageName: 'project',
            viewMode: getPageViewMode(flowAPI),
          }),
        );

        if (isSeoTagsEnabled(flowAPI)) {
          flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
            itemType: 'PORTFOLIO_PROJECTS',
            itemData: { prtoject: projectPageData.project },
            seoData: {},
          });
        }
      }
    },
    exports: {},
  };
});
